<template>
  <div class="con-wrap">
    <div class="con-con">      
      <!-- <img src="https://api.map.baidu.com/staticimage?width=600&height=720&center=106.659446,26.658677&zoom=19"> -->
      <!-- <iframe src="http://api.map.baidu.com/marker?location=26.6587,106.659339&title=众惠基金销售有限公司&content=贵州省贵阳市观山湖区长岭北路贵阳国际金融中心二期商务区第C4栋30层&output=html&src=webapp.baidu.openAPIdemo" style="border: none; width: 600px"></iframe> -->
        <baidu-map class="map" :center="{lng: 106.659339, lat: 26.6587}" :zoom="19" :scroll-wheel-zoom="true" style="width: 666px; height: 720px;">
          <bm-info-window :position="{lng: 106.659339, lat: 26.6587}" title="众惠基金销售有限公司" :show="true">
            <p>贵州省贵阳市观山湖区长岭北路贵阳国际金融中心二期商务区第C4栋30层</p>
          </bm-info-window>
        </baidu-map>
      <div>
        <h3 class="h3-1">
          ABOUT ZHONGHUI
        </h3>
        <h3 class="h3-2">
          众惠基金销售有限公司
        </h3>
        <div class="cc-t">
          <span class="s1">全国统一客服热线</span>
          <span class="s2">合作咨询、投诉建议电话</span>
        </div>
        <div class="cc-text">
          <img src="@/assets/images/myFavors/cc1.png">
          <span class="s1">0851-82209888</span>
          <span class="s2">0851-82209888</span>
        </div>
        <div class="cc-t">
          <span class="s1">电子邮箱</span>
        </div>
        <div class="cc-text">
          <img src="@/assets/images/myFavors/cc2.png">
          <span class="s1">business@hyzhfund.com</span>
        </div>
        <div class="cc-t">
          <span class="s1">传真电话</span>
        </div>
        <div class="cc-text">
          <img src="@/assets/images/myFavors/cc3.png">
          <span class="s1">0851-86909950</span>
        </div>
        <div class="cc-t">
          <span class="s1">总部地址</span>
        </div>
        <div class="cc-text">
          <img src="@/assets/images/myFavors/cc4.png">
          <span class="s1">贵州省贵阳市观山湖区长岭北路贵阳国际金融中心二期商务区
            第C4栋30层<span style="color: #BDC0CB"> | 550081</span></span>
        </div>
        <div class="qrcode">
          <div>
            <img src="@/assets/images/myFavors/qrcode1.png">
            <span>众惠公众号</span>
          </div>
          <div>
            <img src="@/assets/images/myFavors/qrcode2.png">
            <span>众惠微博号</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="less" scoped>
@import url('@/assets/css/zc-common.less');
.con-wrap {
  width: 100%;
  padding-top: 146px;
  padding-bottom: 118px;
  background: url('../../assets/images/myFavors/contactbg.png') no-repeat center center;
  background-size: 100% 100%;
  // margin: 0 auto;
  // margin-top: 70px;
  // padding-top: 76px;
  .con-con {
    width: 1200px;
    height: 720px;
    margin: 0 auto;
    box-shadow: 0px 78.1771px 62.5417px rgba(0, 0, 0, 0.07), 0px 23.5681px 18.8545px rgba(0, 0, 0, 0.0456112), 0px 9.78898px 7.83119px rgba(0, 0, 0, 0.035), 0px 3.54048px 2.83239px rgba(0, 0, 0, 0.0243888);
    border-radius: 3.90885px;
    overflow: hidden;
    display: flex;
    &>img {
      width: 50%;
      height: 100%;
    }
    &>div {
      width: 50%;
      height: 100%;
      background: #fff;
      padding-left: 30px;
      padding-top: 30px;
      padding-bottom: 24px;
      padding-right: 60px;
      .h3-1 {
        font-size: 32px;
        color: #CE9B63;
        opacity: 0.5;
      }
      .h3-2 {
        font-weight: bold;
        font-size: 32px;
        color: #25293D;
        padding-bottom: 23px;
      }
      .cc-t, .cc-text {
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding-left: 35px;
        font-size: 14px;
        color: #8691A8;
        margin-top: 18px;
        .s1 {
          display: inline-block;
          min-width: 130px;
        }
        .s2 {
          margin-left: 100px;
        }
      }
      .cc-text {
        padding-left: 0px;
        font-size: 18px;
        color: #25293D;
        img {
          width: 20px;
          height: 20px;
        }
        .s1 {
          margin-left: 15px;
        }
      }
      .qrcode {
        width: 100%;
        margin-top: 52px;
        display: flex;
        justify-content: flex-end;
        &>div {
          display: flex;
          flex-direction: column;
          align-items: center;
          &:last-child {
            margin-left: 20px;
          }
          &>img {
            width: 133px;
            height: 133px;
          }
          &>span {
            margin-top: 12px;
            font-size: 14px;
            color: #BDC0CB;
          }
        }
      }
    }
  }
}
</style>